import React from "react";
import ViewActivityLog from "../../common/ui/view-section/ViewActivityLog";
import GeneralHeader from "../../common/ui/GeneralHeader";
import {MC_Backend} from "../../common/MC_Backend";
import {MC_Action_Log} from "../../common/iot/MC_Action_Log";
import {Chip} from "@mui/material";
import "./Logs.css";
import {MC_Action_Log_Categories} from "../../common/iot/MC_Action_Log_Categories";

function Logs(props: any) {
    // Constants
    const FILTER_NONE: string = "none";
    const FILTER_ADMIN: string = "admin";
    const FILTER_BILLING: string = "billing";
    const FILTER_ORGS: string = "orgs";
    const FILTER_USERS: string = "users";
    const FILTER_DEVICES: string = "devices";
    const FILTER_ALERTS: string = "alerts";
    // State
    const [filter, setFilter] = React.useState<string>(FILTER_NONE);
    // Helper fns
    const refreshFn = () => {
        const backend = MC_Backend.getInstance();
        if (!backend.overviewCtx.loading) {
            backend.loadOverview().finally();
        }
    };
    const noFilter = (x: MC_Action_Log) => true;
    const adminFilter = (x: MC_Action_Log) => x.user_admin; // Performed by admin
    const billingFilter = MC_Action_Log_Categories.getBillingLogsFilter();
    const orgsFilter = (x: MC_Action_Log) => (x.config.target_organization_id != null);
    const usersFilter = (x: MC_Action_Log) => (x.config.target_user_id != null);
    const devicesFilter = (x: MC_Action_Log) => (x.config.target_device_id != null);
    const alertsFilter = (x: MC_Action_Log) => (x.config.target_alert_id != null);
    // UI
    let filterFn: (x: MC_Action_Log) => boolean = noFilter;
    let desc: string = "Unknown...";
    if (filter === FILTER_NONE) {
        filterFn = noFilter;
        desc = "Here is a summary of all recent events performed within the platform.";
    } else if (filter === FILTER_ADMIN) {
        filterFn = adminFilter;
        desc = "Here is a summary of recent events performed by admins.";
    } else if (filter === FILTER_BILLING) {
        filterFn = billingFilter;
        desc = "Here is a summary of recent billing events.";
    } else if (filter === FILTER_ORGS) {
        filterFn = orgsFilter;
        desc = "Here is a summary of recent events relating to organizations.";
    } else if (filter === FILTER_USERS) {
        filterFn = usersFilter;
        desc = "Here is a summary of recent events relating to users.";
    } else if (filter === FILTER_DEVICES) {
        filterFn = devicesFilter;
        desc = "Here is a summary of recent events relating to dryers.";
    } else if (filter === FILTER_ALERTS) {
        filterFn = alertsFilter;
        desc = "Here is a summary of recent events relating to alerts.";
    }
    const chipSx: any = {m: 1};
    return (
        <div className={"logs-root"}>

            {/* Title */}
            <GeneralHeader title={"Platform Logs"} refreshAction={refreshFn}/>

            {/* Explanation */}
            <p className={"small-note"}>
                Here are all the platform logs collected within the last 2 weeks.
                <br/>
                Contact your JAS rep if you need access to older historical logs..
            </p>

            {/* Filter control */}
            <div className={"logs-filter-div"}>
                {/* All logs */}
                <Chip className={"filter-chip"}
                      sx={chipSx}
                      label=" All Logs " color={"primary"}
                      variant={(filter === FILTER_NONE) ? "filled" : "outlined"}
                      onClick={() => setFilter(FILTER_NONE)}
                />
                {/* Admin logs */}
                <Chip className={"filter-chip"}
                      sx={chipSx}
                      label="Admin Logs" color={"primary"}
                      variant={(filter === FILTER_ADMIN) ? "filled" : "outlined"}
                      onClick={() => setFilter(FILTER_ADMIN)}
                />
                {/* Billing logs */}
                <Chip className={"filter-chip"}
                      sx={chipSx}
                      label="Billing Logs" color={"primary"}
                      variant={(filter === FILTER_BILLING) ? "filled" : "outlined"}
                      onClick={() => setFilter(FILTER_BILLING)}
                />
                {/* Organization logs */}
                <Chip className={"filter-chip"}
                      sx={chipSx}
                      label="Organization Logs" color={"primary"}
                      variant={(filter === FILTER_ORGS) ? "filled" : "outlined"}
                      onClick={() => setFilter(FILTER_ORGS)}
                />
                {/* User logs */}
                <Chip className={"filter-chip"}
                      sx={chipSx}
                      label="User Logs" color={"primary"}
                      variant={(filter === FILTER_USERS) ? "filled" : "outlined"}
                      onClick={() => setFilter(FILTER_USERS)}
                />
                {/* Device logs */}
                <Chip className={"filter-chip"}
                      sx={chipSx}
                      label="Dryer Logs" color={"primary"}
                      variant={(filter === FILTER_DEVICES) ? "filled" : "outlined"}
                      onClick={() => setFilter(FILTER_DEVICES)}
                />
                {/* Alerts logs */}
                <Chip className={"filter-chip"}
                      sx={chipSx}
                      label="Alert Logs" color={"primary"}
                      variant={(filter === FILTER_ALERTS) ? "filled" : "outlined"}
                      onClick={() => setFilter(FILTER_ALERTS)}
                />
            </div>

            <br/>

            {/* Content */}
            <div className={"logs-content"}>
                {/* Display logs */}
                <ViewActivityLog
                    pageSize={10} title={"Logs"}
                    desc={desc}
                    logFilterFn={filterFn}
                />
            </div>
            <br/><br/><br/>
        </div>
    );
}

export default Logs;