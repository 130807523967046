import "./TextInputRow.css";
import {TextField} from "@mui/material";


export interface TextInputRowProps {
    label: string;
    value: string;
    updateValue: (newValue: string) => void;
}
function TextInputRow(props: TextInputRowProps) {
    return (
        <div className={"tir-root"}>
            <TextField
                className={"tip-tf"} variant="filled"
                label={props.label} value={props.value}
                onChange={(ev) => props.updateValue(ev.target.value)}
            />
        </div>
    );
}

export default TextInputRow;