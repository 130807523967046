import "./DryerNetworkTab.css";
import {Alert, Button, CircularProgress, Divider, Paper, Snackbar, TextareaAutosize} from "@mui/material";
import DetailRow from "../../../common/ui/DetailRow";
import React from "react";
import {MC_Device} from "../../../common/iot/MC_Device";
import TextInputRow from "../../../common/ui/misc/TextInputRow";
import {MC_Backend} from "../../../common/MC_Backend";
import {Refresh, Send} from "@mui/icons-material";
import {CustomDeviceConstants} from "../../../custom/CustomDeviceConstants";
import {MC_Constants} from "../../../common/MC_Constants";

export interface DryerNetworkTabProps {
    device: MC_Device;
}
function DryerNetworkTab({device}: DryerNetworkTabProps) {
    const recent: any = device.recent;
    const parseRecentVal = (param: string): number | null => {
        if (recent != null && recent.hasOwnProperty(param)) {
            return recent[param];
        }
        return null;
    };
    const parseRecentStr = (param: string): string => {
        if (recent != null && recent.hasOwnProperty(param)) {
            return "" + recent[param];
        }
        return "N/A";
    };
    // IT Data
    const mac: string = parseRecentStr("mac");
    const localIP: string = parseRecentStr("local_ip");
    const subnetMask: string = parseRecentStr("subnet_mask");
    const gatewayIP: string = parseRecentStr("gateway_ip");
    const dnsIP: string = parseRecentStr("dns_ip");
    const dhcpIP: string = parseRecentStr("dhcp_ip");
    // Target IP for control
    let latestTargetIPStr: string = parseRecentStr("target_ip");
    if (latestTargetIPStr !== "N/A") {
        latestTargetIPStr = "192.168.5." + latestTargetIPStr;
    }
    const targetIPVal: number | null = parseRecentVal("target_ip");
    const initialTargetIPStr: string = (targetIPVal != null) ? targetIPVal + "" : "";
    // State
    const [errorSnackOpen, setErrorSnackOpen] = React.useState<boolean>(false);
    const [snackErrMsg, setSnackErrMsg] = React.useState<string | null>(null);
    const [successSnackOpen, setSuccessSnackOpen] = React.useState<boolean>(false);
    const [currentIPStr, setCurrentIPStr] = React.useState<string>(initialTargetIPStr);
    const [opInProgress, setOpInProgress] = React.useState<boolean>(false);
    let currentIPVal: number | null = parseInt(currentIPStr);
    currentIPVal = (currentIPVal == null || isNaN(currentIPVal)) ? null : currentIPVal;
    const currentIPErrorMessage: string | null = (currentIPVal != null && currentIPVal >= 1 && currentIPVal <= 100)
        ? null
        : "Invalid Target PLC IP (Must be 1-100)"
    ;
    // Helper Functions
    const updateTargetIP = () => {
        if (!opInProgress && currentIPErrorMessage == null) {
            setOpInProgress(true);
            const dataObj: any = {
                target_ip: currentIPVal!
            };
            MC_Backend.getInstance().callDeviceRemoteAction(
                device.id,
                CustomDeviceConstants.DRYER_ACTION_EDIT_TARGET_IP,
                dataObj
            ).then((resp) => {
                if (resp.success) {
                    setSuccessSnackOpen(true);
                } else {
                    console.error("resp.error_message: " + resp.error_message + " JSON=" + JSON.stringify(resp.error_message));
                    setSnackErrMsg(resp.error_message);
                    setErrorSnackOpen(true);
                }
            }).catch((err) => {
                setSnackErrMsg(err);
                setErrorSnackOpen(true);
            }).finally(() => {
                setOpInProgress(false);
            });
        }
    };
    // UI
    return (
        <div className={"network-tab-root"}>

            {/* Snack bars */}
            <Snackbar
                open={errorSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setErrorSnackOpen(false)}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Error: {(snackErrMsg != null) ? snackErrMsg : "Unknown Error"}
                </Alert>
            </Snackbar>
            <Snackbar
                open={successSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setSuccessSnackOpen(false)}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Updated Target PLC IP Address.
                </Alert>
            </Snackbar>

            {/* IT Info */}
            <Paper className={"content-start"} elevation={3}>
                <div className={"center-row"}>
                    <p className={"small-note"}>
                        Latest update from module: {MC_Constants.getReadableDateStr(device.last_post)}
                    </p>
                </div>
                <DetailRow label={"Target PLC IP"} value={latestTargetIPStr}/>
                <br/>
                <Divider className={"divider"} />
                <br/>
                <DetailRow label={"MAC Address"} value={mac}/>
                <DetailRow label={"Module IP"} value={localIP}/>
                <br/>
                <Divider className={"divider"} />
                <br/>
                <DetailRow label={"Subnet Mask"} value={subnetMask}/>
                <DetailRow label={"Gateway IP"} value={gatewayIP}/>
                <DetailRow label={"DNS IP"} value={dnsIP}/>
                <DetailRow label={"DHCP IP"} value={dhcpIP}/>
            </Paper>
            <br/>

            {/* Target IP */}
            <Paper className={"content-center"} elevation={3}>
                <p className={(currentIPVal != null) ? "note" : "error-note"}>
                    This module will attempt to communicate
                    with the PLC located at 192.168.5.{(currentIPVal != null) ? currentIPVal + "" : "X"}
                </p>
                <TextInputRow
                    label={"Target PLC IP Address"}
                    value={currentIPStr}
                    updateValue={(newStr) => setCurrentIPStr(newStr)}
                />
                <br/>
                <div className={"update-btn-div"}>
                    {/* Show button if no op in progress */}
                    {!opInProgress &&
                        <Button
                            variant="contained"
                            color={"primary"}
                            disabled={opInProgress || currentIPErrorMessage != null}
                            onClick={updateTargetIP}
                        >
                            <div className={"update-btn-content"}>
                                <span className={"update-btn-span"}>Update</span>
                                <Send className={"update-btn-icon"} fontSize={"medium"}/>
                            </div>
                        </Button>
                    }
                    {/* Show spinner if op in progress */}
                    {opInProgress &&
                        <CircularProgress size={"30px"} color={"primary"}/>
                    }
                </div>

                <div className={"small-note-div"}>
                    <span className={(currentIPErrorMessage != null) ? "small-error-note" : "small-note"}>
                        {(currentIPErrorMessage != null) && currentIPErrorMessage}
                        {(currentIPErrorMessage == null) && "After updating, it may take a few moments to be reflected."}
                    </span>
                </div>
            </Paper>

        </div>
    );
}

export default DryerNetworkTab;