import "./GeneralHeader.css";
import {Button, Divider, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import {Add, ArrowBack, Help, Person, Refresh, TabletAndroid} from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";

export interface GeneralHeaderInterface {
    title: string;
    icon?: "org" | "user" | "device" | "issue";
    iconColor?: "primary" | "blue" | "green" | "red" | "orange";
    backAction?: () => void;
    createAction? : () => void;
    refreshAction?: () => void;
}

function GeneralHeader(props: GeneralHeaderInterface) {
    const iconSize: "small" | "medium" | "large" = "medium";
    const theme = useTheme();
    const useCondensedUI: boolean = useMediaQuery(theme.breakpoints.down("md"));
    const sideClasses: string = "gh-side " + ((!useCondensedUI) ? "gh-side-normal" : "gh-side-sm");
    const iconColor = props.iconColor;
    let colorSx: any = {};
    if (iconColor === "blue") {
        colorSx = {color: "blue"};
    } else if (iconColor === "green") {
        colorSx = {color: "green"};
    } else if (iconColor === "red") {
        colorSx = {color: "red"};
    } else if (iconColor === "orange") {
        colorSx = {color: "orange"};
    } else {
        // Default (primary)
        colorSx = {color: "primary.main"};
    }
    return (
        <div>

            {/* Header row */}
            <div className={"header-div"}>

                {/* Optional back or create btn */}
                <div className={sideClasses}>
                    {
                        (props.backAction != null) &&
                        <Button className={"header-btn"} variant="outlined" color={"primary"} onClick={props.backAction}>
                            <div className={"btn-content"}>
                                <ArrowBack className={"btn-icon"} fontSize={iconSize}/>
                                {(!useCondensedUI) &&
                                    <React.Fragment>
                                        <span className={"spacer"}/>
                                        <span className={"btn-span"}>Back</span>
                                    </React.Fragment>
                                }
                            </div>
                        </Button>
                    }
                    {
                        (props.backAction == null && props.createAction != null) &&
                        <Button className={"header-btn"} variant="contained" color={"success"} onClick={props.createAction}>
                            <div className={"btn-content"}>
                                <Add className={"btn-icon"} fontSize={iconSize}/>
                                {(!useCondensedUI) &&
                                    <React.Fragment>
                                        <span className={"spacer"}/>
                                        <span className={"btn-span"}>Create</span>
                                    </React.Fragment>
                                }
                            </div>
                        </Button>
                    }
                </div>

                {/* Title div */}
                <div className={"title-div"}>
                    {/* Optional icon */}
                    {(props.icon != null && props.icon === "org") && <BusinessIcon sx={colorSx} fontSize={"large"} />}
                    {(props.icon != null && props.icon === "device") && <TabletAndroid sx={colorSx} fontSize={"large"} />}
                    {(props.icon != null && props.icon === "user") && <Person sx={colorSx} fontSize={"large"} />}
                    {(props.icon != null && props.icon === "issue") && <Help sx={colorSx} fontSize={"large"} />}
                    {/* Space between icon */}
                    {(props.icon != null) && <span className={"icon-spacer"}/>}
                    {/* Title */}
                    <h2>{props.title}</h2>
                </div>

                {/* Optional refresh button */}
                <div className={sideClasses}>
                    {
                        (props.refreshAction != null) &&
                        <Button className={"header-btn"} variant="contained" color={"primary"} onClick={props.refreshAction}>
                            <div className={"btn-content"}>
                                {(!useCondensedUI) &&
                                    <React.Fragment>
                                        <span className={"btn-span"}>Refresh</span>
                                        <span className={"spacer"}/>
                                    </React.Fragment>
                                }
                                <Refresh className={"btn-icon"} fontSize={iconSize}/>
                            </div>
                        </Button>
                    }
                </div>

            </div>

            {/* Divider */}
            <Divider className={"divider"}/>
            <br/>

        </div>

    );
}

export default GeneralHeader;