import "./Overview.css";
import DetailRow from "../../common/ui/DetailRow";
import {Paper} from "@mui/material";
import React, {useContext} from "react";
import {MC_Backend} from "../../common/MC_Backend";
import {MC_Overview} from "../../common/iot/MC_Overview";
import {OverviewContext} from "../../App";
import MCLoadingBar from "../../common/ui/misc/MCLoadingBar";
import GeneralHeader from "../../common/ui/GeneralHeader";
import ViewActivityLog from "../../common/ui/view-section/ViewActivityLog";
import {MC_Constants} from "../../common/MC_Constants";

function Overview(props: any) {
    let initialCtx = useContext(OverviewContext);
    let refreshFn = () => {
        let backend = MC_Backend.getInstance();
        backend.loadOverview().finally(() => {
            // The result will be updated via context provider
        });
    };
    // Load overview if needed
    React.useEffect(() => {
        if (initialCtx.overview == null && !initialCtx.loading && initialCtx.errMsg == null) {
            refreshFn();
        }
    }, [initialCtx.overview, initialCtx.loading, initialCtx.errMsg]);
    // UI
    return (
        <OverviewContext.Consumer>
            {(curCtx) => {
                if (curCtx == null) {
                    return <p className={"error-note"}>Error no context.</p>;
                }
                let curOverview: MC_Overview | null = curCtx.overview;
                let lastReportTS: string = (curOverview != null)
                    ? MC_Constants.getReadableDateStr(curOverview.clientDBSummary.timestamp)
                    : "N/A"
                ;
                const startWeekTS: string = (curOverview != null)
                    ? MC_Constants.getReadableDateStr(curOverview.recentAlertsSummary.start)
                    : "N/A"
                ;
                const endWeekTS: string = (curOverview != null)
                    ? MC_Constants.getReadableDateStr(curOverview.recentAlertsSummary.end)
                    : "N/A"
                ;
                //const emailReportTS: string = (curOverview != null) ? CustomConstants.getReadableDateStr(curOverview.lastEmailReport.timestamp) : "N/A";
                return (
                    <div className={"overview-root"}>

                        {/* Title */}
                        <GeneralHeader title={"Overview"} refreshAction={(!curCtx.loading) ? refreshFn : () => {}}/>

                        {/* Loading div */}
                        <MCLoadingBar loadingMessage={"Loading Overview..."}
                                      loading={curCtx.loading} errorMessage={curCtx.errMsg}
                        />

                        {/* Content */}
                        <div className={"overview-content"}>

                            {/* Display data if available */}
                            {
                                (curOverview != null) &&
                                <React.Fragment>

                                    {/* Summary tiles */}
                                    <div className={"overview-summary-row"}>

                                        {/* Summary info */}
                                        <Paper elevation={3} className={"paper-content"}>
                                            <span className={"subtitle"}>Platform Summary</span>
                                            <span className={"subfooter"}>Updated: {lastReportTS}</span>
                                            <div className={"subdetail-div"}>
                                                <DetailRow label={"Dryers"} value={curOverview.clientDBSummary.deviceCount + ""}/>
                                                <DetailRow label={"Organizations"} value={curOverview.clientDBSummary.orgCount + ""}/>
                                                <DetailRow label={"Users"} value={curOverview.clientDBSummary.userCount + ""}/>
                                                <DetailRow label={"Total Alerts"} value={curOverview.clientDBSummary.alertCount + ""}/>
                                                <DetailRow label={"Total Data Rows"} value={curOverview.sensorDBSummary.rowCount + ""}/>
                                            </div>
                                        </Paper>

                                        {/* Recent Maintenance info */}
                                        <Paper elevation={3} className={"paper-content"}>
                                            <span className={"subtitle"}>Last Week</span>
                                            <span className={"subfooter"}>{startWeekTS} - {endWeekTS}</span>
                                            <div className={"subdetail-div"}>
                                                <DetailRow label={"Dryers Added"} value={curOverview.clientDBSummary.newDeviceCount + ""}/>
                                                <DetailRow label={"Alerts Triggered"} value={curOverview.recentAlertsSummary.recentAlerts + ""}/>
                                                <DetailRow label={"Organizations Created"} value={curOverview.clientDBSummary.newOrgCount + ""}/>
                                                <DetailRow label={"Users Joined"} value={curOverview.clientDBSummary.newUserCount + ""}/>
                                                <DetailRow label={"Data Rows Collected"} value={curOverview.sensorDBSummary.newRowCount + ""}/>
                                            </div>
                                        </Paper>

                                    </div>

                                    {/* Logs Row */}
                                    <div className={"overview-logs-row"}>
                                        {/* Global logs */}
                                        <ViewActivityLog
                                            pageSize={10}
                                            title={"Global Logs"}
                                            desc={"Here is a summary of recent events performed within the platform."}
                                            logFilterFn={(x) => true}
                                            hideLoadingBar={true}
                                        />
                                    </div>

                                </React.Fragment>
                            }

                            <br/><br/>

                        </div>
                    </div>
                );
            }}

        </OverviewContext.Consumer>
    );
}

export default Overview;