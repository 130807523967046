import {MC_Device} from "../common/iot/MC_Device";

// Class to define what devices can be created
export interface CustomDevice {
    rawType: string,
    fullTypeName: string,
    description: string
}
// Create device
export class CustomDeviceFactory {

    // Devices
    public static TYPE_DRYER: string = "DRYER";
    public static FULL_TYPE_DRYER: string = "Grain Dryer";

    public static SUPPORTED_DEVICES: CustomDevice[] = [
        {
            rawType: this.TYPE_DRYER,
            fullTypeName: this.FULL_TYPE_DRYER,
            description: ""
        }
    ];

    public static fromData(data: any): MC_Device {
        const type: string | null = (data[MC_Device.FIELD_TYPE] != null)
            ? data[MC_Device.FIELD_TYPE] as string
            : null
        ;
        if (type == null) {
            throw "Missing device type";
        }
        // Custom device types
        switch (type) {}
        // Return base device as default
        return new MC_Device(data);
    }

    public static getArrayFromData(dataArray: any[]): MC_Device[] {
        let devices: MC_Device[] = [];
        for (let i = 0; i < dataArray.length; i++) {
            try {
                devices = devices.concat(this.fromData(dataArray[i]));
            } catch (e) {
                console.error("Device parse err: " + e + ": " + JSON.stringify(dataArray[i]));
            }
        }
        return devices;
    }

    public static getFullDeviceType(type: string): string {
        switch (type) {
            case this.TYPE_DRYER: return this.FULL_TYPE_DRYER;
        }
        return "Unknown";
    }

}
